

























import Vue from 'vue';
import { ContentType } from '../types';

export default Vue.extend({
  props: {
    contentType: {
      type: Object as () => ContentType,
      required: true,
    },
  },
});
