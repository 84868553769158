var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-btn",
    _vm._b(
      { attrs: { label: _vm.label, icon: _vm.icon }, on: { click: _vm.open } },
      "q-btn",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }