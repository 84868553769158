

































import Vue from 'vue';
import _ from 'lodash';
import { getTextContent } from '../util';
import isGranted from '../authorization/isGranted.js';
import { Field } from '../types';
import Editor from '@/shared/form/Editor.vue';
import WriteContentBtn from '../writing/WriteContentBtn.vue';

export default Vue.extend({
  components: { Editor, WriteContentBtn },
  props: {
    field: {
      type: Object as () => Field,
      required: true,
    },
    toolbarElementId: String,
  },
  computed: {
    limitReached(): boolean {
      if (!this.field.maxChars) return false;
      return (this.valueChars > this.field.maxChars);
    },
    valueChars(): number {
      if (!this.field.value) return 0;
      return getTextContent(this.field.value).length;
    },
  },
  methods: {
    onWrite(content: string): void {
      this.onInput(content);
    },
    onInput(value: string): void {
      const field = _.cloneDeep(this.field);
      field.value = value;
      this.$emit('change', field);
    },
    isGranted,
  },
});
