
















import Vue from 'vue';

export default Vue.extend({
  components: {
    StickyPageToolbar: () => import('mycorems_app/layout/StickyPageToolbar'),
  },
  props: {
    editorToolbarId: String,
  }
});
