var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loader", { attrs: { task: _vm.task } }),
      _c("editor-toolbar", {
        attrs: { "editor-toolbar-id": "template-editor-toolbar" },
      }),
      _vm.template
        ? _c("auto-save-doc-editor", {
            attrs: {
              resource: _vm.template,
              "save-action": _vm.updateFields,
              headings: _vm.headings,
              "last-save-text": "Template saved",
              "editor-toolbar-id": "template-editor-toolbar",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }