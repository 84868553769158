
















import Vue from 'vue';
import { Field } from '../types';
import EditorField from './EditorField.vue';

export default Vue.extend({
  components: {
    EditorField,
  },
  props: {
    fields: {
      type: Array as () => Field[],
      required: true,
    },
    toolbarElementId: String,
  },
  methods: {
    updateField(field: Field) {
      const index = this.fields.findIndex((f: Field) => f.id === field.id);
      if (index >= 0) {
        this.$emit('change', [field]);
      }
    },
  },
});
