var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex justify-between" }, [
        _c("h6", { staticClass: "text-h6" }, [_vm._v(_vm._s(_vm.field.label))]),
        _c(
          "div",
          [
            _c("write-content-btn", {
              attrs: { icon: "fa fa-comments", flat: "", "no-caps": "" },
              on: { write: _vm.onWrite },
            }),
            _vm.field.maxChars
              ? _c("span", { staticClass: "text-caption" }, [
                  _c(
                    "span",
                    {
                      class: [
                        {
                          "text-positive": !_vm.limitReached,
                          "text-negative": _vm.limitReached,
                        },
                      ],
                    },
                    [
                      _vm.limitReached
                        ? _c("q-icon", {
                            attrs: {
                              name: "fa fa-exclamation-triangle",
                              title: "Character limit reached.",
                            },
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "q-ml-xs" }, [
                        _vm._v(_vm._s(_vm.valueChars)),
                      ]),
                    ],
                    1
                  ),
                  _c("span", [
                    _vm._v(
                      "/" + _vm._s(_vm.field.maxChars) + " characters used"
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("editor", {
        staticClass: "q-mt-xs",
        attrs: {
          value: _vm.field.value,
          "toolbar-element-id": _vm.toolbarElementId,
          readonly: !_vm.isGranted("EDIT_CONTENT"),
        },
        on: { input: _vm.onInput },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }