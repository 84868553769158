interface Resource {
  id: string,
}

interface ResourcesSaver {
  (resources: Resource[]|any): Promise<Resource[]|any>,
}

export default class ResourceAutoSaver {
  saveHandler: ResourcesSaver;

  timer: number|null;

  resources: Resource[];

  resourcesInQueue: Resource[];

  task: Promise<Resource[]>|null;

  constructor(saveHandler: ResourcesSaver) {
    this.saveHandler = saveHandler;
    this.timer = null;
    this.resources = [];
    this.resourcesInQueue = [];
    this.task = null;
  }

  save(resources: Resource[], onSave: Function): void {
    if (!resources.length) return;
    this.resourcesInQueue = resources;
    this.addOrReplaceResources(resources);
    this.delayedSave(onSave);
  }

  private addOrReplaceResources(resources: Resource[]) {
    resources.forEach((resource: Resource) => {
      const index = this.resources.findIndex(f => f.id === resource.id);
      if (index >= 0) {
        this.resources.splice(index, 1, resource);
      } else {
        this.resources.push(resource);
      }
    });
  }

  private delayedSave(onSave: Function): void {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.sequenceSave(onSave);
    }, 2000);
  }

  private sequenceSave(onSave: Function): void {
    this.task = this
      .finishSaving()
      .then(() => {
        const { resources } = this;
        this.clearResources();
        return this.saveHandler(resources);
      });
    onSave(this.task);
  }

  private finishSaving(): Promise<any> {
    if (this.task) return this.task;
    return Promise.resolve();
  }

  private clearResources(): void {
    this.resources = [];
  }
}
