var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tags has-addons" }, [
    _c("span", { staticClass: "tag is-info is-medium is-uppercase" }, [
      _vm._v(_vm._s(_vm.type)),
    ]),
    _c("span", { staticClass: "tag is-medium" }, [_vm._v(_vm._s(_vm.title))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }