

































import { HasTaskMixin, Loader } from 'mycorems-app-shared';
import { Document } from '../types';
import repository from './document-repository';
import { updateFields } from './document-service';
import DocumentToolbar from './DocumentToolbar.vue';
import AutoSavingDocEditor from '../editor/AutoSaveDocEditor.vue';

interface Data {
  document: Document|null,
}

export default HasTaskMixin.extend({
  components: {
    Loader,
    DocumentToolbar,
    AutoSavingDocEditor,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      document: null,
    };
  },
  computed: {
    documentHeadings(): string[] {
      if (!this.document) return [];
      const { contentItem } = this.document;
      return [
        contentItem.account.name,
        this.document.title,
        contentItem.contentType.name,
      ];
    },
  },
  watch: {
    id: {
      handler(): void {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    update(document: Document): void {
      this.document = document;
    },
    load(): void {
      this.task = repository.find(this.id);
      this.task.then((document: Document) => {
        this.document = document;
      });
    },
    updateFields,
  },
});
