var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("editor-toolbar", {
    staticClass: "q-px-md q-py-xs",
    attrs: { "editor-toolbar-id": _vm.editorToolbarId },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c("span", { staticClass: "text-bold text-uppercase" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.document.documentType.name) +
                  " - " +
                  _vm._s(_vm.document.title) +
                  "\n      "
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "right",
        fn: function () {
          return [
            _c("document-status", { attrs: { document: _vm.document } }),
            !_vm.isApproved
              ? _c("submit-document-btn", {
                  attrs: { document: _vm.document, flat: "" },
                  on: {
                    submitted: function ($event) {
                      return _vm.$emit("submitted", $event)
                    },
                  },
                })
              : _vm._e(),
            !_vm.isApproved
              ? _c("approve-document-btn", {
                  attrs: { document: _vm.document, flat: "" },
                  on: {
                    approved: function ($event) {
                      return _vm.$emit("approved", $event)
                    },
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }