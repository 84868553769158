var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loader", { attrs: { task: _vm.task } }),
      _vm.document
        ? _c("document-toolbar", {
            attrs: {
              document: _vm.document,
              "editor-toolbar-id": "document-editor-toolbar",
            },
            on: { approved: _vm.update, submitted: _vm.update },
          })
        : _vm._e(),
      _vm.document
        ? _c("auto-saving-doc-editor", {
            attrs: {
              resource: _vm.document,
              headings: _vm.documentHeadings,
              "save-action": _vm.updateFields,
              "last-save-text": "Document saved",
              "editor-toolbar-id": "document-editor-toolbar",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }