




















import { HasTaskMixin, AsyncSavingStatus } from 'mycorems-app-shared';
import ResourceAutoSaver from '@/shared/async/ResourceAutoSaver';
import Document from '@/shared/document/Document.vue';
import { Field } from '../types';
import EditorHeader from './EditorHeader.vue';
import EditorFields from './EditorFields.vue';

interface Resource {
  id: string,
  fields: Field[],
}

interface SaveAction {
  (id: string, fields: Field[]): Promise<Field[]>,
}

interface Data {
  fields: Field[],
  autoSaver: ResourceAutoSaver|null,
}

export default HasTaskMixin.extend({
  components: {
    Document,
    EditorFields,
    EditorHeader,
    AsyncSavingStatus,
  },
  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
    saveAction: {
      type: Function as unknown as () => SaveAction,
      required: true,
    },
    headings: {
      type: Array as () => String[],
      default(): String[] {
        return [];
      },
    },
    lastSaveText: String,
    editorToolbarId: String,
  },
  data(): Data {
    return {
      fields: this.resource.fields,
      autoSaver: null,
    };
  },
  methods: {
    autoSave(fields: Field[]): void {
      fields.forEach(this.update);
      if (!this.autoSaver) return;
      this.autoSaver.save(fields, (task: Promise<Field[]>) => {
        this.task = task;
        this.task.then((sf: Field[]) => {
          sf.forEach(this.update);
        });
      });
    },
    update(field: Field) {
      const index = this.fields.findIndex(f => f.id === field.id);
      if (index >= 0) {
        this.fields.splice(index, 1, field);
      }
    },
    save(fields: Field[]): Promise<Field[]> {
      return this.saveAction(this.resource.id, fields);
    },
  },
  created(): void {
    this.autoSaver = new ResourceAutoSaver(this.save);
  },
});
